"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withStyledChildren = void 0;
const react_1 = require("react");
const react_is_1 = require("react-is");
const match_at_rule_1 = require("./match-at-rule");
function withStyledChildren({ componentChildren, childStyles, }) {
    let children = (0, react_is_1.isFragment)(componentChildren)
        ? // This probably needs to be recursive
            componentChildren.props.children
        : componentChildren;
    children = react_1.Children.map(children, (child, index) => {
        /**
         * For every child:
         *  For every style:
         *    For every atRule:
         *      Ensure all atRules match
         *    If all atRules match, push the style
         *  Add the styles to the child
         * Return the children
         *
         * This is a inefficient and makes parent: selectors a bit slow
         * as we repeat the logic for nearly every child. Sometimes that's required (eg. nthChild)
         * but typically not.
         *
         * We should split the childStyles into static and dynamic and only loop the dynamic
         * ones for each child.
         */
        const matchingStyles = [];
        for (const { atRules, ...styles } of childStyles) {
            const matches = atRules.every(([rule, params]) => {
                return (0, match_at_rule_1.matchChildAtRule)({
                    nthChild: index + 1,
                    rule,
                    params,
                });
            });
            if (matches) {
                matchingStyles.push(styles);
            }
        }
        return (0, react_1.cloneElement)(child, {
            style: child.props.style
                ? [child.props.style, matchingStyles]
                : matchingStyles.length > 0
                    ? matchingStyles
                    : undefined,
        });
    });
    return children;
}
exports.withStyledChildren = withStyledChildren;
