"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTailwind = void 0;
const react_native_1 = require("react-native");
const runtime_styles_1 = require("./runtime-styles");
const with_styled_props_1 = require("./with-styled-props");
const platform_1 = require("./context/platform");
const style_sheet_1 = require("./context/style-sheet");
const device_media_1 = require("./context/device-media");
const component_1 = require("./context/component");
const color_scheme_1 = require("./context/color-scheme");
function useTailwind({ hover = false, focus = false, active = false, } = {}) {
    const { platform } = (0, platform_1.usePlatform)();
    const { colorScheme } = (0, color_scheme_1.useColorScheme)();
    const stylesheetContext = (0, style_sheet_1.useStyleSheet)();
    const deviceMediaContext = (0, device_media_1.useDeviceMedia)();
    const componentInteraction = (0, component_1.useComponent)();
    function callback(className = "", { flatten = true } = {}) {
        const [styles, childStyles] = (0, runtime_styles_1.getRuntimeStyles)({
            className,
            hover,
            focus,
            active,
            stylesheetContext,
            platform,
            colorScheme,
            componentInteraction,
            deviceMediaContext,
        });
        const result = (flatten ? react_native_1.StyleSheet.flatten(styles) : styles);
        if (childStyles.length > 0) {
            result[with_styled_props_1.ChildClassNameSymbol] = childStyles;
        }
        return result;
    }
    return callback;
}
exports.useTailwind = useTailwind;
