"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useComponent = exports.ComponentContext = void 0;
const react_1 = require("react");
exports.ComponentContext = (0, react_1.createContext)({
    hover: false,
    focus: false,
    active: false,
});
function useComponent() {
    return (0, react_1.useContext)(exports.ComponentContext);
}
exports.useComponent = useComponent;
