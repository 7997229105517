"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInteraction = void 0;
const react_1 = require("react");
function useInteraction({ isComponent, disabled = false, focusable = true, onFocus, onBlur, onHoverIn, onHoverOut, onPressIn, onPressOut, className = "", }) {
    const [hover, setHover] = (0, react_1.useState)(false);
    const [focus, setFocus] = (0, react_1.useState)(false);
    const [active, setActive] = (0, react_1.useState)(false);
    const handleFocus = (0, react_1.useCallback)((event) => {
        if (disabled) {
            return;
        }
        if (focusable) {
            if (onFocus) {
                onFocus(event);
            }
            setFocus(true);
        }
    }, [disabled, focusable, onFocus, setFocus]);
    const handleBlur = (0, react_1.useCallback)((event) => {
        if (disabled) {
            return;
        }
        if (onBlur) {
            onBlur(event);
        }
        setFocus(false);
    }, [disabled, onBlur, setFocus]);
    const handleHoverIn = (0, react_1.useCallback)((event) => {
        if (disabled) {
            return;
        }
        if (onHoverIn) {
            onHoverIn(event);
        }
        setHover(true);
    }, [disabled, onHoverIn, setHover]);
    const handleHoverOut = (0, react_1.useCallback)((event) => {
        if (disabled) {
            return;
        }
        if (onHoverOut) {
            onHoverOut(event);
        }
        setHover(false);
    }, [disabled, onHoverOut, setHover]);
    const handlePressIn = (0, react_1.useCallback)((event) => {
        if (disabled) {
            return;
        }
        if (onPressIn) {
            onPressIn(event);
        }
        setActive(true);
        setFocus(false);
    }, [disabled, onPressIn, setActive]);
    const handlePressOut = (0, react_1.useCallback)((event) => {
        if (disabled) {
            return;
        }
        if (onPressOut) {
            onPressOut(event);
        }
        setActive(false);
    }, [disabled, onPressOut, setActive]);
    const interaction = {
        active,
        hover,
        focus,
    };
    if (isComponent) {
        Object.assign(interaction, {
            onBlur: handleBlur,
            onFocus: handleFocus,
            onHoverIn: handleHoverIn,
            onHoverOut: handleHoverOut,
            onPressIn: handlePressIn,
            onPressOut: handlePressOut,
        });
    }
    else {
        if (className.includes("focus:")) {
            interaction.onBlur = handleBlur;
            interaction.onFocus = handleFocus;
        }
        if (className.includes("hover:")) {
            interaction.onHoverIn = handleHoverIn;
            interaction.onHoverOut = handleHoverOut;
        }
        if (className.includes("active:")) {
            interaction.onPressIn = handlePressIn;
            interaction.onPressOut = handlePressOut;
        }
    }
    return interaction;
}
exports.useInteraction = useInteraction;
