"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyleSheet = exports.StyleSheetContext = void 0;
const react_1 = require("react");
(_a = globalThis.tailwindcss_react_native_style) !== null && _a !== void 0 ? _a : (globalThis.tailwindcss_react_native_style = {});
(_b = globalThis.tailwindcss_react_native_media) !== null && _b !== void 0 ? _b : (globalThis.tailwindcss_react_native_media = {});
exports.StyleSheetContext = (0, react_1.createContext)({
    styles: {},
    media: {},
});
function useStyleSheet() {
    return (0, react_1.useContext)(exports.StyleSheetContext);
}
exports.useStyleSheet = useStyleSheet;
