"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRuntimeStyles = void 0;
const react_native_1 = require("react-native");
const match_at_rule_1 = require("./match-at-rule");
const selector_1 = require("./shared/selector");
function getRuntimeStyles({ className, hover, focus, active, stylesheetContext, platform, colorScheme, componentInteraction, deviceMediaContext, }) {
    const { styles, media } = stylesheetContext;
    const tailwindStyles = [];
    const childStyles = [];
    const transforms = [];
    for (const name of className.split(/\s+/)) {
        if (!name)
            continue; // Happens if there are leading or trailing whitespace
        const selector = (0, selector_1.normalizeSelector)(name);
        /**
         * If we have static styles, add them
         */
        if (styles[selector]) {
            const { transform, ...style } = flattenIfRWN(styles[selector]);
            tailwindStyles.push(style);
            if (transform) {
                transforms.push(...transform);
            }
        }
        /**
         * Media styles contain atRules and need to be validated
         */
        if (media[selector]) {
            const atRuleStyles = media[selector].map((atRules, index) => ({
                ...flattenIfRWN(styles[`${selector}.${index}`]),
                atRules,
            }));
            for (const styleRecord of atRuleStyles) {
                let isForChildren = false;
                const { atRules, transform, ...style } = styleRecord;
                const atRulesResult = atRules.every(([rule, params]) => {
                    /**
                     * This is a match string, but it makes sense
                     * Child selectors look like this and will always start with (>
                     *
                     * @selector (> *:not(:first-child))
                     * @selector (> *)
                     */
                    if (rule === "selector" && params.startsWith("(>")) {
                        isForChildren = true;
                        return true;
                    }
                    return (0, match_at_rule_1.matchAtRule)({
                        rule,
                        params,
                        hover,
                        active,
                        focus,
                        platform,
                        colorScheme,
                        componentInteraction,
                        deviceMediaContext,
                    });
                });
                if (isForChildren) {
                    childStyles.push(styleRecord);
                }
                else if (atRulesResult) {
                    tailwindStyles.push(style);
                    if (transform) {
                        transforms.push(...transform);
                    }
                }
            }
        }
    }
    if (transforms.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tailwindStyles.push({ transform: transforms });
    }
    if (childStyles.length > 0) {
        return [tailwindStyles, childStyles];
    }
    return [tailwindStyles, []];
}
exports.getRuntimeStyles = getRuntimeStyles;
function flattenIfRWN(value) {
    // For RWN <=0.17 we need to convert the styleID into an object to handle
    // merging transform.
    //
    // This is a performance issue, but hopefully move people will move onto
    // RWN 0.18 and use CSS
    return typeof value === "number" ? react_native_1.StyleSheet.flatten(value) : value;
}
