"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withStyledProps = exports.ChildClassNameSymbol = void 0;
exports.ChildClassNameSymbol = Symbol("tailwind-child");
function withStyledProps({ tw, classes, propsToTransform, styleProp, componentProps, spreadProps = [], classProps = [], preview, }) {
    const mainStyles = tw(classes, { flatten: false });
    const styledProps = {};
    for (const prop of classProps) {
        const value = componentProps[prop];
        if (typeof value === "string") {
            if (preview) {
                styledProps[prop] = undefined;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mainStyles[prop] = Object.values(tw(value, {
                    flatten: false,
                }))[1];
            }
            else {
                const entries = Object.entries(tw(value, { flatten: true }));
                if (entries.length > 0) {
                    styledProps[prop] = undefined;
                    for (const [key, value] of entries) {
                        styledProps[key] = value;
                    }
                }
            }
        }
    }
    for (const prop of spreadProps) {
        const value = componentProps[prop];
        if (typeof value === "string") {
            const entries = Object.entries(tw(value, { flatten: true }));
            if (entries.length > 0) {
                styledProps[prop] = undefined;
                for (const [key, value] of entries) {
                    styledProps[key] = value;
                }
            }
        }
    }
    if (propsToTransform) {
        for (const prop of propsToTransform) {
            const value = componentProps[prop];
            if (typeof value === "string") {
                styledProps[prop] = tw(value, { flatten: false });
            }
        }
    }
    const style = styleProp
        ? [mainStyles, styleProp]
        : Array.isArray(mainStyles) && mainStyles.length > 0
            ? mainStyles
            : "$$css" in mainStyles
                ? mainStyles
                : undefined;
    return {
        childStyles: mainStyles[exports.ChildClassNameSymbol],
        style,
        ...componentProps,
        ...styledProps,
    };
}
exports.withStyledProps = withStyledProps;
