"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeSelector = void 0;
/**
 * normalize a selector to be object key friendly
 *
 * We also remove non-word characters for readability/testing purposes
 */
function normalizeSelector(selector, { important } = {}) {
    const leadingDots = "^\\.";
    const leadingChild = "^>";
    const nonWordCharactersExceptDash = new RegExp("[^a-zA-Z0-9-]+", "g");
    if (important) {
        selector = selector.replace(new RegExp(`^${important}`), "");
    }
    return selector
        .trim()
        .replace(new RegExp(leadingDots), "")
        .replace(new RegExp(leadingChild), "")
        .replace(nonWordCharactersExceptDash, "_");
}
exports.normalizeSelector = normalizeSelector;
