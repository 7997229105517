"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTailwind = void 0;
const classname_to_inline_style_1 = require("./classname-to-inline-style");
const platform_1 = require("./context/platform");
const use_tailwind_native_1 = require("./use-tailwind.native");
function useTailwind(useTailwindOptions) {
    const { platform, preview } = (0, platform_1.usePlatform)();
    if (platform === "web" && preview) {
        return ((className = "", { flatten = true } = {}) => {
            return flatten
                ? (0, classname_to_inline_style_1.classNameToInlineStyle)(className, useTailwindOptions)
                : { $$css: true, [className]: className };
        });
    }
    return (0, use_tailwind_native_1.useTailwind)(useTailwindOptions);
}
exports.useTailwind = useTailwind;
