"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceMedia = exports.DeviceMediaProvider = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const use_device_orientation_1 = require("./use-device-orientation");
const DeviceMediaContext = (0, react_1.createContext)({
    width: 0,
    height: 0,
    orientation: "portrait",
});
function DeviceMediaProvider(props) {
    // const { reduceMotionEnabled: reduceMotion } = useAccessibilityInfo() // We should support this
    const { width, height } = (0, react_native_1.useWindowDimensions)();
    const orientation = (0, use_device_orientation_1.useDeviceOrientation)()
        .portrait
        ? "portrait"
        : "landscape";
    return (react_1.default.createElement(DeviceMediaContext.Provider, { value: {
            width,
            height,
            orientation,
        }, ...props }));
}
exports.DeviceMediaProvider = DeviceMediaProvider;
function useDeviceMedia() {
    return (0, react_1.useContext)(DeviceMediaContext);
}
exports.useDeviceMedia = useDeviceMedia;
