"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styled = void 0;
const react_1 = require("react");
const component_1 = require("./context/component");
const use_interaction_1 = require("./use-interaction");
const with_styled_children_1 = require("./with-styled-children");
const with_styled_props_1 = require("./with-styled-props");
const use_tailwind_1 = require("./use-tailwind");
const with_class_names_1 = require("./with-class-names");
const platform_1 = require("./context/platform");
/**
 * Actual implementation
 */
function styled(Component, styledBaseClassNameOrOptions, maybeOptions = {}) {
    const { props: propsToTransform, spreadProps, classProps, } = typeof styledBaseClassNameOrOptions === "object"
        ? styledBaseClassNameOrOptions
        : maybeOptions;
    const baseClassName = typeof styledBaseClassNameOrOptions === "string"
        ? styledBaseClassNameOrOptions
        : maybeOptions === null || maybeOptions === void 0 ? void 0 : maybeOptions.baseClassName;
    function Styled({ className, tw: twClassName, baseTw, style: styleProp, children: componentChildren, ...componentProps }, ref) {
        const { platform, preview } = (0, platform_1.usePlatform)();
        const { classes, allClasses, isComponent } = (0, with_class_names_1.withClassNames)({
            baseClassName,
            className,
            twClassName,
            baseTw,
            componentProps,
            propsToTransform,
            spreadProps,
            classProps,
        });
        const { hover, focus, active, ...handlers } = (0, use_interaction_1.useInteraction)({
            className: allClasses,
            isComponent,
            platform,
            preview,
            ...componentProps,
        });
        const tw = (0, use_tailwind_1.useTailwind)({
            hover,
            focus,
            active,
        });
        const { childStyles, ...styledProps } = (0, with_styled_props_1.withStyledProps)({
            tw,
            classes,
            styleProp,
            propsToTransform,
            componentProps,
            spreadProps,
            classProps,
            preview,
        });
        const children = childStyles
            ? (0, with_styled_children_1.withStyledChildren)({
                componentChildren,
                childStyles,
            })
            : componentChildren;
        const element = (0, react_1.createElement)(Component, {
            ...handlers,
            ...styledProps,
            children,
            ref,
        });
        return !isComponent
            ? element
            : (0, react_1.createElement)(component_1.ComponentContext.Provider, {
                children: element,
                value: { hover, focus, active },
            });
    }
    if (typeof Component !== "string") {
        Styled.displayName = `TailwindCssReactNative.${Component.displayName || Component.name || "NoName"}`;
    }
    return (0, react_1.forwardRef)(Styled);
}
exports.styled = styled;
