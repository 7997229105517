"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withExpoSnack = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const style_sheet_1 = require("./style-sheet");
const fetched = {};
const canUseCSS = false;
// const canUseCSS = typeof StyleSheet.create({ test: {} }).test !== "number";
function ExpoSnackWrapper({ children }) {
    return react_native_1.Platform.OS === "web" && canUseCSS ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("script", { id: "tailwind-cdn", type: "text/javascript", src: "https://cdn.tailwindcss.com" }), children] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children }));
}
function withExpoSnack(Component, theme = {}) {
    function dangerouslyCompileStyles(css, store) {
        const themeString = JSON.stringify(theme);
        css = css.replace(/\s+/g, " ").trim();
        const cacheKey = `${css}${themeString}`;
        if (!css)
            return;
        if (fetched[cacheKey])
            return;
        fetch(`https://nativewind-demo-compiler.vercel.app/api/compile?css=${css}&theme=${themeString}`)
            .then((response) => response.json())
            .then(({ body }) => {
            fetched[cacheKey] = true;
            store.create(body);
            // This the async, the store will have already cached
            // incorrect results, so we need to clear these
            // and set the correct ones
            for (const className of css.split(/\s+/)) {
                delete store.snapshot[className];
            }
            for (const key of Object.keys(store.snapshot)) {
                if (key.includes(css)) {
                    delete store.snapshot[key];
                    const [, bit] = key.split(".");
                    store.prepare(css, { baseBit: Number.parseInt(bit) });
                }
            }
            store.notify();
        })
            .catch((error) => {
            console.error(error);
        });
    }
    if (!canUseCSS) {
        style_sheet_1.NativeWindStyleSheet.setDangerouslyCompileStyles(dangerouslyCompileStyles);
        style_sheet_1.NativeWindStyleSheet.setOutput({
            default: "native",
        });
    }
    return () => ((0, jsx_runtime_1.jsx)(ExpoSnackWrapper, { children: (0, jsx_runtime_1.jsx)(Component, {}) }));
}
exports.withExpoSnack = withExpoSnack;
