"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withClassNames = void 0;
function withClassNames({ baseClassName, className, baseTw, twClassName, componentProps, propsToTransform = [], spreadProps = [], classProps = [], }) {
    var _a, _b;
    const classes = [
        (_a = baseTw !== null && baseTw !== void 0 ? baseTw : baseClassName) !== null && _a !== void 0 ? _a : "",
        (_b = twClassName !== null && twClassName !== void 0 ? twClassName : className) !== null && _b !== void 0 ? _b : "",
    ].join(" ");
    const isComponent = classes.split(/\s+/).includes("component");
    const allClasses = [classes];
    for (const prop of [...propsToTransform, ...spreadProps, ...classProps]) {
        const componentProp = componentProps[prop];
        if (typeof componentProp === "string") {
            allClasses.push(componentProp);
        }
    }
    return {
        classes,
        allClasses: allClasses.join(" "),
        isComponent,
    };
}
exports.withClassNames = withClassNames;
