"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchChildAtRule = exports.matchAtRule = void 0;
const css_mediaquery_1 = require("css-mediaquery");
function matchAtRule({ rule, params, hover, active, focus, componentInteraction, platform, colorScheme, deviceMediaContext: { width, height, orientation }, }) {
    if (rule === "pseudo-class" && params === "hover") {
        return hover;
    }
    else if (rule === "pseudo-class" && params === "focus") {
        return focus;
    }
    else if (rule === "pseudo-class" && params === "active") {
        return active;
    }
    else if (rule === "component" && params === "hover") {
        return componentInteraction.hover;
    }
    else if (rule === "component" && params === "focus") {
        return componentInteraction.focus;
    }
    else if (rule === "component" && params === "active") {
        return componentInteraction.active;
    }
    else if (rule === "media") {
        return (0, css_mediaquery_1.match)(params, {
            "aspect-ratio": width / height,
            "device-aspect-ratio": width / height,
            type: platform,
            width,
            height,
            "device-width": width,
            "device-height": width,
            orientation,
            "prefers-color-scheme": colorScheme,
        });
    }
}
exports.matchAtRule = matchAtRule;
function matchChildAtRule({ nthChild, rule, params, }) {
    if (rule === "selector" &&
        params === "(> *:not(:first-child))" &&
        nthChild > 1) {
        return true;
    }
    else if (rule === "selector" && params === "(> *)") {
        return true;
    }
    return false;
}
exports.matchChildAtRule = matchChildAtRule;
